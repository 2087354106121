import { Attributes, useCallback, useMemo } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Address } from 'shared/types/account/Address';
import { Cart } from 'shared/types/cart';
import { Discount } from 'shared/types/cart/Discount';
import { CustomFields } from 'shared/types/cart/LineItem';
import { Order } from 'shared/types/cart/Order';
import countries from 'shared/types/countries.json';
import { Variant, Product } from 'shared/types/product';
import useSWR, { mutate } from 'swr';
import { SSOToken } from 'components/padi-ui/checkout/address-form';
import useI18n from 'helpers/hooks/useI18n';
import { getCookie } from 'helpers/utils/cookies';
import { getLocalStorage } from 'helpers/utils/localStorage';
import mapCosts from 'helpers/utils/mapCosts';
import { sdk } from 'sdk';
import { revalidateOptions, useAccount } from 'frontastic';
import { CartDetails, UseCartReturn } from './types';

const useCart = (): UseCartReturn => {
  const extensions = sdk.composableCommerce;

  const { currency } = useI18n();
  const { account, defaultBillingAddress } = useAccount();

  const result = useSWR('/action/cart/getCart', extensions.cart.getCart, revalidateOptions);

  const shippingMethodsResults = useSWR(
    '/action/cart/getShippingMethods',
    extensions.cart.getShippingMethods,
    revalidateOptions,
  );

  const data = result.data?.isError ? {} : { data: result.data?.data as unknown as Cart };

  const shippingMethods = shippingMethodsResults.data?.isError ? {} : { data: shippingMethodsResults.data?.data };

  const totalItems = (data.data as Cart)?.lineItems?.reduce((acc, curr) => acc + (curr.count as number), 0) ?? 0;

  const isEmpty = !data?.data?.lineItems?.length;

  const isShippingAccurate = !!data?.data?.shippingInfo;

  const transaction = useMemo(() => mapCosts({ reference: 'cart', cart: data.data, currency }), [data.data, currency]);

  const addItem = useCallback(
    async (product: Product, variant: Variant, quantity: number, custom?: CustomFields, isReferral = false) => {
      const diveShopLocalStorage = getLocalStorage('affiliateDiveShop');
      const storeNumber = diveShopLocalStorage || '';
      const impactCookie = getCookie('PADI.Impact.irclickid') || '';
      let shippingAddress: Address = {};
      let billingAddress: Address = {};
      let affiliateId = '';
      if (!isReferral && account?.addresses && account.addresses.length > 0) {
        const address = defaultBillingAddress ?? account.addresses[0];
        const country = countries.find((c) => c.twoLetterISORegionName === address.country);
        const baseAddress: Address = {
          firstName: account?.firstName,
          lastName: account?.lastName,
          streetName: address.streetName,
          postalCode: address.postalCode,
          city: address.city,
          state: address.state,
          country: address.country,
          additionalStreetInfo: address.additionalStreetInfo,
          phone: address.phone,
        };
        const additionalAddressInfo = {
          ...baseAddress,
          email: account?.email,
          taxAddress: {
            countryName: country?.englishName,
            countryId: country?.id,
          },
        };
        billingAddress = {
          ...baseAddress,
          department: storeNumber,
          additionalAddressInfo: JSON.stringify(additionalAddressInfo),
          company: impactCookie,
        } as Address;
        shippingAddress = {
          // ...billingAddress,
          state: baseAddress.state,
          country: baseAddress.country,
          additionalAddressInfo: JSON.stringify(additionalAddressInfo),
        } as Address;
      }
      // update cart's custom type and adress for tax calculation
      const token = window.localStorage.getItem('idToken');
      if (!isReferral && token) {
        const user = jwtDecode<SSOToken>(token || '');
        affiliateId = user['custom:affiliate_id'];
        billingAddress.externalId = affiliateId;
        if (account?.addresses && account.addresses.length > 0 && !data?.data?.billingAddress?.addressId) {
          // if (account?.addresses && account.addresses.length > 0 && !data?.data?.billingAddress?.addressId && totalItems === 0) {
          const addressPayload = {
            email: account?.email,
            affiliateId,
            storeNumber,
            billing: billingAddress,
            shipping: shippingAddress,
          };
          await sdk.callAction({
            actionName: 'cart/updateCartWithAddress',
            payload: addressPayload,
          });
        }
      }

      const extensions = sdk.composableCommerce;
      const payload = {
        product: {
          productId: product.productId,
          productKey: product.productKey,
          productType: product.productType,
        },
        variant: {
          sku: variant.sku,
          count: quantity,
          attributes: variant.attributes,
        },
        custom,
        affiliateId,
        address: billingAddress,
      };
      const res = await extensions.cart.addItem(payload);
      mutate('/action/cart/getCart', res);
    },
    [
      account?.addresses,
      account?.email,
      account?.firstName,
      account?.lastName,
      data?.data?.billingAddress?.addressId,
      defaultBillingAddress,
    ],
  );

  const orderCart = useCallback(async () => {
    const res = await sdk.callAction({ actionName: 'cart/checkout' });
    mutate('/action/cart/getCart');

    return (res.isError ? {} : res.data) as Order;
  }, []);

  const getOrder = useCallback(async (orderId: Order['orderId']) => {
    const res = await sdk.callAction({ actionName: 'cart/getOrder', payload: { orderId: orderId } });
    mutate('/action/cart/getCart');

    return (res.isError ? {} : res.data) as Order;
  }, []);

  const orderHistory = useCallback(async () => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.cart.getOrderHistory();

    return res.isError ? ([] as Order[]) : (res.data as Order[]);
  }, []);

  const getProjectSettings = useCallback(async () => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.project.getSettings();

    return res.isError ? {} : res.data;
  }, []);

  const removeItem = useCallback(async (lineItemId: string) => {
    const extensions = sdk.composableCommerce;

    const payload = {
      lineItem: { id: lineItemId },
    };

    const res = await extensions.cart.removeItem(payload);
    mutate('/action/cart/getCart', res);
  }, []);

  const updateItem = useCallback(async (lineItemId: string, newQuantity: number) => {
    const extensions = sdk.composableCommerce;

    const payload = {
      lineItem: {
        id: lineItemId,
        count: newQuantity,
      },
    };
    const res = await extensions.cart.updateItem(payload);
    mutate('/action/cart/getCart', res);
  }, []);

  const updateCart = useCallback(async (payload: CartDetails): Promise<Cart> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.cart.updateCart(payload);

    mutate('/action/cart/getCart', res);

    return (res.isError ? {} : res.data) as Cart;
  }, []);

  const setShippingMethod = useCallback(async (shippingMethodId: string) => {
    const extensions = sdk.composableCommerce;

    const payload = {
      shippingMethod: {
        id: shippingMethodId,
      },
    };

    const res = await extensions.cart.setShippingMethod(payload);

    mutate('/action/cart/getCart', res);
  }, []);

  const redeemDiscountCode = useCallback(async (code: string) => {
    const extensions = sdk.composableCommerce;

    const payload = {
      code: code,
    };
    const res = await extensions.cart.redeemDiscountCode(payload);

    if (!res.isError && (res.data as Cart).cartId) {
      mutate('/action/cart/getCart', res);
    } else {
      throw new Error('code not valid');
    }
  }, []);

  const removeDiscountCode = useCallback(async (discount: Discount) => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.cart.removeDiscountCode({ discountId: discount.discountId as string });

    mutate('/action/cart/getCart', res);
  }, []);

  return {
    ...data,
    totalItems,
    isEmpty,
    isShippingAccurate,
    transaction,
    addItem,
    updateCart,
    setShippingMethod,
    removeItem,
    updateItem,
    shippingMethods,
    orderCart,
    getOrder,
    orderHistory,
    getProjectSettings,
    redeemDiscountCode,
    removeDiscountCode,
  };
};

export default useCart;
