import React from 'react';
import { ProjectConfig } from 'types/project-config';
import LoggedIn from './loggedin';
import LoggedOut from './loggedout';
import { ReferenceFieldLink } from 'components/padi-ui/header/types';
interface Props {
  loggedIn: boolean;
  projectConfig: ProjectConfig;
  userMenu?: ReferenceFieldLink[];
}

const AccountDropdown: React.FC<Props> = ({ loggedIn, projectConfig, userMenu }) => {
  return loggedIn ? <LoggedIn {...{ projectConfig, userMenu }} /> : <LoggedOut {...{ projectConfig, userMenu }} />;
};

export default AccountDropdown;
