import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import PadiDiveShopStorage from 'components/padi-dive-shop/dive-shop-storage';
import HeaderLogo from 'components/padi-ui/header/header-logo';
import HeaderNavigationDesktop from 'components/padi-ui/header/header-navigation/header-navigation-desktop';
import HeaderNavigationMobile from 'components/padi-ui/header/header-navigation/header-navigation-mobile';
import { PadiHeaderProps, ReferenceFieldLink } from 'components/padi-ui/header/types';
import UtilitySection from 'components/padi-ui/header/utility-section';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getUserData, getUserMemberType, getUserIdToken } from 'helpers/padi/cognito';
import { isNonProd } from 'helpers/utils/environment';
import { mediumDesktop } from 'helpers/utils/screensizes';

const CommercetoolsSearch = dynamic(() => import('components/commercetools-ui/atoms/search'));

const PadiHeader: React.FC<PadiHeaderProps> = ({
  topbarMenus,
  megaMenus,
  anonMenulinks,
  studentMenulinks,
  proMenulinks,
  categories,
  logo,
  logoLink,
  emptyCartTitle,
  emptyCartSubtitle,
  emptyCartImage,
  emptyCartCategories,
  emptyWishlistTitle,
  emptyWishlistSubtitle,
  emptyWishlistImage,
  emptyWishlistCategories,
  searchParams,
  projectConfig,
}) => {
  const Search = CommercetoolsSearch;

  const [idToken, setIdToken] = useState('');
  const [userData, setUserData] = useState({});
  const [userMenu, setUserMenu] = useState<ReferenceFieldLink[]>(anonMenulinks);
  const [userMemberType, setUserMemberType] = useState('');

  const [isDesktopSizeOrLarger] = useMediaQuery(mediumDesktop);

  const padiNonProdUrls = (url: string) => {
    if (!url) return '';

    const domains = [
      {
        prod: 'https://learning.padi.com',
        nonprod: projectConfig?.padiDomains?.learning,
      },
      {
        prod: 'https://account.padi.com',
        nonprod: projectConfig?.padiDomains?.account,
      },
      {
        prod: 'https://pro.padi.com',
        nonprod: projectConfig?.padiDomains?.pro,
      },
      {
        prod: 'https://www.padi.com',
        nonprod: projectConfig?.padiDomains?.www,
      },
    ];

    let npurl;
    domains.some((domain) => {
      if (url.includes(domain.prod)) {
        npurl = url.replace(domain.prod, domain.nonprod ?? domain.prod);
        return npurl;
      }
    });
    return npurl ?? url;
  };

  useEffect(() => {
    const getIdToken = getUserIdToken();
    if (getIdToken) {
      setIdToken(getIdToken);
    }

    const cognitoUserData = getUserData();
    const nonProdLinks: ReferenceFieldLink[] = [];

    if (cognitoUserData) {
      setUserData(cognitoUserData);
      const getMemberType = getUserMemberType(cognitoUserData['custom:affiliate_type_id']);

      if (getMemberType) {
        setUserMemberType(getMemberType);

        switch (getMemberType) {
          case 'pro':
            if (isNonProd()) {
              proMenulinks?.map((link) => {
                if (link?.url.type == 'link') {
                  const nonprodurl = padiNonProdUrls(link?.url?.link);
                  nonProdLinks.push({
                    label: link.label,
                    url: {
                      link: nonprodurl,
                      type: link.url.type,
                      openInNewWindow: link.url.openInNewWindow,
                    },
                  });
                }
              });
            }
            setUserMenu(nonProdLinks.length ? nonProdLinks : studentMenulinks);
            break;
          case 'student':
            if (isNonProd()) {
              studentMenulinks?.map((link) => {
                if (link?.url.type == 'link') {
                  const nonprodurl = padiNonProdUrls(link?.url?.link);
                  nonProdLinks.push({
                    label: link.label,
                    url: {
                      link: nonprodurl,
                      type: link.url.type,
                      openInNewWindow: link.url.openInNewWindow,
                    },
                  });
                }
              });
            }
            setUserMenu(nonProdLinks.length ? nonProdLinks : studentMenulinks);
            break;
          default:
            setUserMenu(nonProdLinks.length ? nonProdLinks : studentMenulinks);
            break;
        }
      }
    } else {
      if (isNonProd()) {
        anonMenulinks.map((link) => {
          if (link?.url?.type == 'link') {
            const nonprodurl = padiNonProdUrls(link?.url?.link);
            nonProdLinks.push({
              label: link.label,
              url: {
                link: nonprodurl,
                type: link.url.type,
                openInNewWindow: link.url.openInNewWindow,
              },
            });
          }
        });
        setUserMenu(nonProdLinks.length ? nonProdLinks : anonMenulinks);
      }
    }
  }, [getUserIdToken, getUserData, studentMenulinks, proMenulinks]);

  return (
    <>
      <PadiDiveShopStorage urlParams={searchParams as Record<string, string>}></PadiDiveShopStorage>
      {/* @todo Add topbar here once we remove topbar componoent from component group */}
      {/* {isDesktopSizeOrLarger && topbarMenus?.length && <HeaderTopbar menus={topbarMenus}></HeaderTopbar>} */}
      <header className="relative h-auto w-full bg-white lg:h-76">
        <div aria-label="Top" className="mx-auto flex h-full max-w-7xl items-center justify-between px-24 lg:px-32">
          <HeaderNavigationMobile
            logo={logo}
            logoLink={logoLink}
            megaMenus={megaMenus}
            userMenu={userMenu}
            topbarMenus={topbarMenus}
            projectConfig={projectConfig}
          />

          <div className="flex h-full w-full items-center justify-start">
            <HeaderLogo
              logo={logo}
              logoLink={logoLink}
              imageClassName="flex h-full w-116 justify-start text-16 font-bold md:text-28"
            />
            <HeaderNavigationDesktop megaMenus={megaMenus} />
          </div>

          <div className="flex w-full justify-end xl:w-fit">
            <div className="relative hidden w-full py-6 lg:block">
              <Search categories={categories} />
            </div>

            <UtilitySection
              emptyCartTitle={emptyCartTitle}
              emptyCartSubtitle={emptyCartSubtitle}
              emptyCartImage={emptyCartImage}
              emptyCartCategories={emptyCartCategories}
              emptyWishlistTitle={emptyWishlistTitle}
              emptyWishlistSubtitle={emptyWishlistSubtitle}
              emptyWishlistImage={emptyWishlistImage}
              emptyWishlistCategories={emptyWishlistCategories}
              userMenu={userMenu}
              projectConfig={projectConfig}
            />
          </div>
        </div>

        <div id="mobile-header-search" className="relative w-full bg-white px-24 py-12 lg:hidden" hidden>
          <Search categories={categories} />
        </div>
      </header>
    </>
  );
};
export default PadiHeader;
