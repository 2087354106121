import React from 'react';
import dynamic from 'next/dynamic';

const Brazil = dynamic(() => import('./brazil'));
const China = dynamic(() => import('./china'));
const France = dynamic(() => import('./france'));
const Germany = dynamic(() => import('./germany'));
const HongKong = dynamic(() => import('./hongkong'));
const Italy = dynamic(() => import('./italy'));
const Japan = dynamic(() => import('./japan'));
const Korea = dynamic(() => import('./korea'));
const Netherlands = dynamic(() => import('./netherlands'));
const Spain = dynamic(() => import('./spain'));
const Thailand = dynamic(() => import('./thailand'));
const SaudiArabia = dynamic(() => import('./saudi-arabia'));
const UnitedKingdom = dynamic(() => import('./united-kingdom'));
const UnitedStates = dynamic(() => import('./united-states'));

type Props = {
  flagName: string;
  className?: string;
};

const Icon: React.FC<Props> = ({ className, flagName }: Props) => {
  const iconClassNames = `h-14 w-21 ${className}`;
  const flags = {
    CN: <China className={iconClassNames} />,
    BR: <Brazil className={iconClassNames} />,
    FR: <France className={iconClassNames} />,
    DE: <Germany className={iconClassNames} />,
    HK: <HongKong className={iconClassNames} />,
    IT: <Italy className={iconClassNames} />,
    JP: <Japan className={iconClassNames} />,
    KR: <Korea className={iconClassNames} />,
    NL: <Netherlands className={iconClassNames} />,
    ES: <Spain className={iconClassNames} />,
    TH: <Thailand className={iconClassNames} />,
    GB: <UnitedKingdom className={iconClassNames} />,
    US: <UnitedStates className={iconClassNames} />,
    AA: <SaudiArabia className={iconClassNames} />,
  };
  return <>{flags[flagName as keyof typeof flags]}</>;
};

export default Icon;
