import { FC } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import { PaymentMethodsProps } from '../types';

const PaymentMethods: FC<PaymentMethodsProps> = ({ paymentMethods }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const paymentLogos = [
    {
      name: 'Google Pay',
      image:
        'https://res.cloudinary.com/padi/image/upload/q_auto/v1706836410/CDN/commercetools/payment-methods/google-pay.svg',
    },
    {
      name: 'Apple Pay',
      image:
        'https://res.cloudinary.com/padi/image/upload/q_auto/v1706836409/CDN/commercetools/payment-methods/apple-pay.svg',
    },
    {
      name: 'PayPal',
      image:
        'https://res.cloudinary.com/padi/image/upload/q_auto/v1706836411/CDN/commercetools/payment-methods/paypal.svg',
    },
    {
      name: 'Visa',
      image:
        'https://res.cloudinary.com/padi/image/upload/q_auto/v1706836411/CDN/commercetools/payment-methods/visa.svg',
    },
    {
      name: 'Master Card',
      image:
        'https://res.cloudinary.com/padi/image/upload/q_auto/v1706836411/CDN/commercetools/payment-methods/mastercard.svg',
    },
    {
      name: 'American Express',
      image:
        'https://res.cloudinary.com/padi/image/upload/q_auto/v1706836409/CDN/commercetools/payment-methods/amex.svg',
    },
    {
      name: 'Diners Club',
      image:
        'https://res.cloudinary.com/padi/image/upload/q_auto/v1706836410/CDN/commercetools/payment-methods/dci.svg',
    },
  ];

  return (
    <div className="mt-20 md:mt-24 lg:mt-16">
      <div className="lg:block">
        <p className="leading-[20px] text-secondary-black">
          {formatCartMessage({ id: 'we.accept', defaultMessage: 'We accept' })}:
        </p>
      </div>
      <div className="mt-26 flex h-30 items-center justify-start gap-7 md:justify-center lg:mt-16 lg:justify-start">
        {paymentLogos.map(({ name, image }) => (
          <div key={name} className="relative h-30">
            <Image
              className="size-full"
              src={image}
              alt={name}
              width={50}
              height={30}
              aria-label={name}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethods;
