import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams, useParams, useRouter } from 'next/navigation';
import { jwtDecode } from 'jwt-decode';
import { Address } from 'shared/types/account/Address';
import countries from 'shared/types/countries.json';
import { Product, Variant } from 'shared/types/product';
import FeedbackIconLayer from 'components/commercetools-ui/atoms/button/feedbackIconLayer';
import usePath from 'helpers/hooks/usePath';
import { getLocalStorage } from 'helpers/utils/localStorage';
import { sdk } from 'sdk';
import { useAccount, useCart } from 'frontastic';
import PadiGeolocationModal from '../../padi-geolocation-modal';
import usePurchase from '../checkout/hooks/usePurchase';

export interface SSOToken {
  sub: string;
  'custom:affiliate_id': string;
}

const ReferralRedeem: React.FC = ({}) => {
  const router = useRouter();
  const { path } = usePath();
  const { locale } = useParams();
  const searchParams = useSearchParams();
  const referralId = searchParams.get('referralId')?.trim() || '';
  const { addItem, data: cart } = useCart();
  const { createStripeCheckoutSession } = usePurchase();
  const { account, defaultBillingAddress, loggedIn, verifyLoggedIn } = useAccount();
  const [redeemed, setRedeemed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [localeStored, setLocaleStored] = useState<boolean>(false);
  const referralSKU = 'CLUBRAF30';

  const userLocale = getLocalStorage('padiUserLocale');
  useEffect(() => {
    const checkLogin = async () => {
      const isLoggedIn = await verifyLoggedIn();
      if (!isLoggedIn) {
        let lvp = `${path}?referralId=${referralId}`;
        if (path && path.startsWith('/')) {
          lvp = lvp.substring(1);
        }
        router.push(`/login?lvp=${lvp}`);
      } else {
        setLocaleStored(true);
      }
    };

    if (userLocale === locale) {
      const referralId = searchParams.get('referralId')?.trim() || '';
      if (referralId) {
        checkLogin();
      } else {
        router.push('/');
      }
    }
  }, [userLocale, locale, isOpened]);

  useEffect(() => {
    if (redeemed || !cart || !localeStored || !referralId) return;

    const redeemReferral = async (user: SSOToken) => {
      const variant: Variant = {
        sku: referralSKU,
      };
      try {
        const payload = getCartAddress(user);
        await sdk.callAction({
          actionName: 'cart/updateCartWithAddress',
          payload,
        });
        await addItem({} as Product, variant, 1, undefined, true);
        setRedeemed(true);
      } catch {
        router.push('/');
      }
    };

    const idToken = window.localStorage.getItem('idToken');
    const user = jwtDecode<SSOToken>(idToken || '');
    if (referralId !== user?.sub) {
      router.push('/courses');
    } else {
      const hasNoTrial =
        !cart?.lineItems || cart.lineItems.length === 0 || cart.lineItems.every((i) => i.variant?.sku !== referralSKU);
      if (hasNoTrial) {
        setIsProcessing(true);
        if (!isProcessing) {
          redeemReferral(user);
        }
      }
    }
  }, [cart, localeStored, redeemed]);

  useEffect(() => {
    if (!cart || !cart.lineItems || cart.lineItems.length === 0 || !redeemed) return;

    const createStripeSession = async () => {
      const url = await createStripeCheckoutSession();
      if (url) router.push(url);
    };

    if (cart.lineItems.length > 1 || cart.lineItems.some((i) => i.variant?.sku !== referralSKU)) {
      router.push('/cart');
    } else {
      createStripeSession();
    }
  }, [redeemed, cart]);

  const getCartAddress = useCallback((user: SSOToken) => {
    const countryCode = getLocalStorage('padiUserCountry')?.toUpperCase() || 'US';
    const country = countries.find((c) => c.twoLetterISORegionName === countryCode);
    let billingAddress: Address = {
      firstName: account?.firstName,
      lastName: account?.lastName,
      country: countryCode,
    };
    if (account?.addresses && account.addresses.length > 0) {
      const address = defaultBillingAddress ?? account.addresses[0];
      billingAddress = {
        ...billingAddress,
        streetName: address.streetName,
        postalCode: address.postalCode,
        city: address.city,
        state: countryCode === address.country ? address.state : '',
        additionalStreetInfo: address.additionalStreetInfo,
        phone: address.phone,
      };
    }
    const additionalAddressInfo = {
      ...billingAddress,
      email: account?.email,
      taxAddress: {
        countryName: country?.englishName,
        countryId: country?.id,
      },
    };
    billingAddress.additionalAddressInfo = JSON.stringify(additionalAddressInfo);
    return {
      email: account?.email,
      affiliateId: user['custom:affiliate_id'] || '',
      storeNumber: getLocalStorage('affiliateDiveShop') || '',
      billing: billingAddress,
      shipping: {
        state: billingAddress.state,
        country: billingAddress.country,
        additionalAddressInfo: JSON.stringify(additionalAddressInfo),
      },
    };
  }, []);

  const syncIsOpen = (open: boolean) => {
    setIsOpened(true);
  };

  return (
    <>
      <PadiGeolocationModal syncIsOpen={syncIsOpen} />
      <FeedbackIconLayer loading={true} variant="secondary" />
    </>
  );
};

export default ReferralRedeem;
