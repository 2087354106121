import { XMarkIcon } from '@heroicons/react/20/solid';
import Link from 'components/commercetools-ui/atoms/link';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { isStringHtml } from 'helpers/utils/isStringHtml';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Reference } from 'types/reference';
import Button, { AnnouncementBannerButtonProps } from './button';
import GlowEffect from './glow-effect';

export interface AnnouncementBannerProps {
  entireHyperlink?: Reference;
  backgroundColor: string;
  bodyText: string;
  bodyTextSize: string;
  showArrowIcon: boolean;
  showCloseButton: boolean;
  button: AnnouncementBannerButtonProps[];
  start: string;
  end: string;
}

const AnnouncementBanner: React.FC<AnnouncementBannerProps> = ({
  entireHyperlink,
  backgroundColor,
  bodyText,
  bodyTextSize,
  showArrowIcon,
  button,
  showCloseButton,
  start,
  end,
}) => {
  const backgroundClass = useMemo(
    () =>
      `flex md:h-50 justify-center items-center gap-x-6 bg-${backgroundColor} px-6 py-2.5 sm:px-3.5 ${
        showCloseButton && 'sm:before:flex-1 relative isolate overflow-hidden '
      }`,
    [backgroundColor],
  );
  const textColor = useMemo(() => {
    let textColor = 'text-white';
    if (
      [
        'padi-blue-light',
        'glow',
        'padi-gray-lighter',
        'padi-gray-light',
        'padi-status-info',
        'padi-status-warning',
      ].includes(backgroundColor)
    ) {
      textColor = 'text-padi-gray-darkest';
    }

    return textColor;
  }, [backgroundColor]);

  const [display, setDisplay] = useState(false);

  const glowEffect = useMemo(() => {
    if (backgroundColor === 'glow') {
      return <GlowEffect />;
    }
    return <></>;
  }, [backgroundColor]);

  const clickCloseButton = useCallback(() => {
    setDisplay(!setDisplay);
  }, [setDisplay]);

  useEffect(() => {
    const now = new Date().getTime();

    const isBeforeEnd = !end || now <= new Date(end).getTime();
    const isAfterStart = !start || now >= new Date(start).getTime();

    if (isBeforeEnd && isAfterStart) {
      setDisplay(true);
    }
  }, [start, end]);

  if (!display) {
    return <></>;
  }
  return (
    <div className={backgroundClass}>
      {glowEffect}
      <div className="flex flex-wrap items-center gap-x-14 gap-y-10">
        <p className={`text-base leading-6 ${textColor}`}>
          <Link link={entireHyperlink} className="flex">
            <>
              {bodyText && isStringHtml(bodyText) ? (
                <div className={`text-${bodyTextSize}`} dangerouslySetInnerHTML={{ __html: bodyText }} />
              ) : (
                bodyText && <Markdown className={`text-${bodyTextSize}`} markdown={bodyText} />
              )}
              {showArrowIcon ? (
                <span className="pl-8" aria-hidden="true">
                  &rarr;
                </span>
              ) : (
                <></>
              )}
            </>
          </Link>
        </p>
        {button?.[0] ? <Button {...button[0]} /> : <></>}
      </div>
      {showCloseButton && (
        <div className="flex flex-1 justify-end">
          <button type="button" className="`-m-12 p-12 focus-visible:outline-offset-[-4px]" onClick={clickCloseButton}>
            <span className="sr-only">Dismiss</span>
            <XMarkIcon aria-hidden="true" className={`h-20 w-20 ${textColor}`} />
          </button>
        </div>
      )}
    </div>
  );
};

export default AnnouncementBanner;
