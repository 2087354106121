import React, { useEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import { MaterialSymbol, SymbolCodepoints } from 'react-material-symbols';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { useFormat } from 'helpers/hooks/useFormat';
import { isStringHtml } from 'helpers/utils/isStringHtml';
import 'react-material-symbols/outlined';

interface SfmcPreference {
  preferenceId: string;
  status: string;
  optInQualifier: string;
  optInPromotion: string;
}

interface SfmcFormPayload {
  recipient: {
    [key: string]: any;
  };
  prefs?: SfmcPreference[];
}
export interface SfmcNewsletterFormProps {
  prefs: SfmcPreference[] | object;
  title: string;
  titleHtag?: string;
  body: string;
  submitButtonText: string;
  legalDisclaimer: string;
  confirmationMessage: string;
  details: object;
}

const SfmcNewsletterForm: React.FC<SfmcNewsletterFormProps> = ({
  prefs,
  title,
  titleHtag,
  body,
  submitButtonText,
  legalDisclaimer,
  confirmationMessage,
  details,
}) => {
  const { locale } = useParams();
  const [formData, setFormData] = useState<SfmcFormPayload>();

  useEffect(() => {
    // Build preference objects.
    const preferences: SfmcPreference[] = [];
    Object.entries(prefs).forEach(([, value]) => {
      const preference = {
        preferenceId: value.prefId,
        status: value.prefStatus,
        optInQualifier: value.optinQualifier,
        optInPromotion: value.optinPromotion,
      };
      preferences.push(preference);
      setFormData({ recipient: {}, prefs: preferences });
    });
  }, [prefs]);

  const [formSuccess, setFormSuccess] = useState(false);
  const [formSuccessMessage, setFormSuccessMessage] = useState('');

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const localeSplit = locale.split('-');
    const language = localeSplit[0];
    setFormData((prevState) => ({
      ...prevState,
      recipient: {
        [fieldName]: fieldValue,
        communication_language_name: language,
        communication_language_name_overwrite: true,
      },
    }));
  };

  const submitForm = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formURL = form.action;
    const formMethod = form.method;

    fetch(formURL, {
      method: formMethod,
      body: JSON.stringify(formData),
      headers: {
        accept: 'application/json',
      },
    }).then(() => {
      setFormData(formData);
      setFormSuccess(true);
      setFormSuccessMessage(confirmationMessage);
    });
  };

  const { formatMessage: localizedForms } = useFormat({ name: 'forms' });

  const emailLabel = localizedForms({
    id: 'email.label',
    defaultMessage: 'Enter address',
  });

  const emailPlaceholder = localizedForms({
    id: 'email.placeholder',
    defaultMessage: 'Enter your email',
  });

  const TitleHtag = titleHtag ? (titleHtag as keyof JSX.IntrinsicElements) : 'h2';

  return (
    <div className="relative isolate overflow-hidden bg-padi-gray-light py-32 sm:py-96 lg:py-128">
      <div className="mx-auto max-w-7xl px-24 lg:px-32">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-32 gap-y-64 lg:max-w-none lg:grid-cols-2">
          {formSuccess ? (
            <div className="max-w-xl text-padi-gray-darkest lg:max-w-lg">
              <Markdown markdown={formSuccessMessage} />
            </div>
          ) : (
            <div className="max-w-xl lg:max-w-lg">
              <TitleHtag className="text-3xl font-bold tracking-tight text-padi-gray-darkest sm:text-4xl">
                {title}
              </TitleHtag>
              <div className="prose mt-16 text-lg leading-8 text-padi-gray-darkest">
                {isStringHtml(body) ? <div dangerouslySetInnerHTML={{ __html: body }} /> : <Markdown markdown={body} />}
              </div>

              <form method="POST" action="https://prefapi.scubadiving.com/recipient" onSubmit={submitForm}>
                <div className="mt-24 flex max-w-md gap-x-16">
                  <label htmlFor="email-address" className="sr-only">
                    {emailLabel}
                  </label>
                  <input
                    id="email-address"
                    name="email_address"
                    type="email"
                    autoComplete="email"
                    required
                    className="min-w-0 flex-auto rounded-md border-0 bg-white px-14 py-8 text-padi-gray-darkest shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-padi-blue sm:text-sm sm:leading-6"
                    placeholder={emailPlaceholder}
                    onChange={handleInput}
                  />
                  <button
                    type="submit"
                    className="flex-none rounded-md bg-padi-blue px-14 py-10 text-sm font-semibold text-white shadow-sm hover:bg-padi-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-padi-blue"
                  >
                    {submitButtonText}
                  </button>
                </div>
              </form>
              <div className="prose mt-16 text-sm text-padi-gray-darker">
                {isStringHtml(legalDisclaimer) ? (
                  <div dangerouslySetInnerHTML={{ __html: legalDisclaimer }} />
                ) : (
                  <Markdown markdown={legalDisclaimer} />
                )}
              </div>
            </div>
          )}

          <dl className="grid grid-cols-1 gap-x-32 gap-y-40 sm:grid-cols-2 lg:pt-8">
            {Object.values(details).map((detail, index) => (
              <div key={index} className="flex flex-col items-start">
                <div className="rounded-md bg-white/5 p-8 ring-1 ring-white/10">
                  <MaterialSymbol icon={detail.icon as SymbolCodepoints} size={36} grade={0} weight={200} />
                </div>
                <dt className="mt-16 font-semibold text-padi-gray-darkest">{detail.title}</dt>
                <dd className="prose mt-8 leading-7 text-padi-gray-darker">
                  {isStringHtml(detail.body) ? (
                    <div dangerouslySetInnerHTML={{ __html: 'test' }} />
                  ) : (
                    <Markdown markdown={detail.body} />
                  )}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default SfmcNewsletterForm;
