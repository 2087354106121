import { useCallback, useEffect, useState } from 'react';
import { getUserData, getUserIdToken } from 'helpers/padi/cognito';
import { useAccount } from 'frontastic';

const getUsersAvatar = async (idToken: string, affiliateId: string, getProjectConfig: Function) => {
  try {
    const proApiDomain = await getProjectConfig('EXTENSION_PADI_APIS_PRO_API_DOMAIN');
    if (proApiDomain.isError) return;
    const response: Response = await fetch(`${proApiDomain.setting}/certifications/photos/getphoto`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ affiliateId: affiliateId }),
      method: 'post',
    });
    const blobContent = await response.blob();
    if (blobContent?.size === 0) {
      return null;
    }
    return URL.createObjectURL(blobContent);
  } catch (e) {
    return null;
  }
};

const getUsersClubEntitlement = async (idToken: string, getProjectConfig: (key: string) => Promise<any>) => {
  if (!idToken) return;
  try {
    const piccoloApiDomain = await getProjectConfig('EXTENSION_PADI_APIS_PICCOLO_API_DOMAIN');
    if (piccoloApiDomain.isError) return;
    const response: Response = await fetch(`${piccoloApiDomain.setting}/customer/entitlements/active`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      method: 'get',
    });

    const result = await response.json();
    if (result) return result;
    return null;
  } catch (e) {
    console.error('----- e: ', e);
    return null;
  }
};

const useGetUserInfo = () => {
  const { getProjectConfig, account } = useAccount();
  const [avatar, setAvatar] = useState<any>();
  const [idToken, setIdToken] = useState<string>();
  const [userData, setUserData] = useState<{ [key: string]: any } | undefined>(undefined);

  const getUserClubEntitlments = useCallback(async () => {
    if (!idToken || !getProjectConfig) return [];
    const response = await getUsersClubEntitlement(idToken, getProjectConfig);
    const result = await response;
    if (result) return result;
    return [];
  }, [getProjectConfig, idToken]);

  useEffect(() => {
    const idToken = getUserIdToken();
    if (idToken) setIdToken(idToken);
    const cognitoUserData = getUserData();
    const affiliateId = cognitoUserData?.['custom:affiliate_id'];
    const fetchAvatar = async (idToken: string, affiliateId: string) => {
      const userAvatar = await getUsersAvatar(idToken, affiliateId, getProjectConfig);
      setAvatar(userAvatar);
    };

    if (!idToken || !affiliateId) {
      return;
    }
    setUserData(cognitoUserData);
    fetchAvatar(idToken, affiliateId);
  }, [getProjectConfig, account]);

  return {
    avatar,
    idToken,
    userData,
    getUserClubEntitlments,
  };
};

export default useGetUserInfo;
