import { Disclosure } from '@headlessui/react';
import { MaterialSymbol } from 'react-material-symbols';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { isStringHtml } from 'helpers/utils/isStringHtml';
import 'react-material-symbols/outlined';
export interface Faq {
  question: string;
  answer: string;
}

export interface FaqSectionProps {
  title: string;
  titleHtag?: string;
  faqs: Faq[];
}

const FaqSection: React.FC<FaqSectionProps> = ({ title, titleHtag, faqs }) => {
  const TitleHtag = titleHtag ? (titleHtag as keyof JSX.IntrinsicElements) : 'h2';

  return (
    <div className="mx-auto mb-24 mt-64 max-w-7xl px-24 lg:px-32">
      <div className="mx-auto max-w-2xl divide-y divide-gray-900/10 lg:mx-0 lg:max-w-none">
        <TitleHtag className="text-2xl font-medium tracking-tight lg:text-3xl lg:font-bold">{title}</TitleHtag>
        <dl className="mt-24 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-center justify-between py-8 text-left text-gray-900">
                      <span className="text-base font-medium leading-6">{faq.question}</span>
                      {open ? (
                        <MaterialSymbol icon="expand_less" size={32} grade={0} weight={200} />
                      ) : (
                        <MaterialSymbol icon="expand_more" size={32} grade={0} weight={200} />
                      )}
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="prose max-w-none pb-14 pr-12 text-padi-gray-darker">
                    {isStringHtml(faq.answer) ? (
                      <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                    ) : (
                      <Markdown className="text-base leading-6" markdown={faq.answer} />
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default FaqSection;
